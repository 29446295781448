import React, { useState } from 'react';
import axios from 'axios';
import { Form, Button, Card } from 'react-bootstrap';

function CreateFilament({ onCreate }) {
    const [materialName, setMaterialName] = useState('');
    const [baseMaterialPriceModifier, setBaseMaterialPriceModifier] = useState('');
    const [description, setDescription] = useState('');
    const [token, setToken] = useState(localStorage.getItem('token') || '');

    const handleCreate = async () => {
        try {
            const response = await axios.post('https://admin.printforgers.com/backend/createFilament', {
                headers: { Authorization: token },
                materialName,
                base_material_price_modifier: baseMaterialPriceModifier,
                description
            });
            alert(response.data.message);
            onCreate();
        } catch (error) {
            console.error('Failed to create filament:', error);
        }
    };

    return (
        <Card className="CreateFilament">
            <Card.Body>
                <Card.Title>Create New Filament</Card.Title>
                <Form.Group>
                    <Form.Label>Material Name</Form.Label>
                    <Form.Control
                        type="text"
                        value={materialName}
                        onChange={(e) => setMaterialName(e.target.value)}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Base Material Price Modifier</Form.Label>
                    <Form.Control
                        type="number"
                        value={baseMaterialPriceModifier}
                        onChange={(e) => setBaseMaterialPriceModifier(e.target.value)}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                        type="text"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                </Form.Group>
                <Button onClick={handleCreate}>Create</Button>
            </Card.Body>
        </Card>
    );
}

export default CreateFilament;
