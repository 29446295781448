import React, { useState, useEffect } from 'react';
import axiosInstance from './axiosInstance'; // Import the axios instance
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import './Home.css';

function Home() {
    const [openOrdersCount, setOpenOrdersCount] = useState(0);
    const [printingOrdersCount, setPrintingOrdersCount] = useState(0);
    const [completeOrdersCount, setCompleteOrdersCount] = useState(0);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [token, setToken] = useState(localStorage.getItem('token') || '');
    const [isLoggedIn, setIsLoggedIn] = useState(!!token);
    const [error, setError] = useState('');

    useEffect(() => {
        if (isLoggedIn) {
            async function fetchOrderCounts() {
                try {
                    const response = await axiosInstance.get('/getOrderCounts');
                    console.log(response);
                    setOpenOrdersCount(response.data.openCount);
                    setPrintingOrdersCount(response.data.printingCount);
                    setCompleteOrdersCount(response.data.deliveredCount + response.data.shippedCount);
                } catch (error) {
                    console.error('Failed to fetch order counts:', error);
                }
            }
            fetchOrderCounts();
        }
    }, [isLoggedIn, token]);

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await axiosInstance.post('/login', { username, password });
            const receivedToken = response.data.token;
            setToken(receivedToken);
            localStorage.setItem('token', receivedToken); // Store token directly
            setIsLoggedIn(true);
            setError(''); // Clear any previous error messages
        } catch (error) {
            console.error('Login failed:', error);
            setError('Login failed. Please check your credentials and try again.');
        }
    };

    return (
        <Container className="HomePage">
            {!isLoggedIn ? (
                <>
                    <h1 className="my-4">Login</h1>
                    {error && <p className="text-danger">{error}</p>}
                    <Form onSubmit={handleLogin}>
                        <Form.Group controlId="formBasicUsername">
                            <Form.Label>Username</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group controlId="formBasicPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Enter password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </Form.Group>

                        <Button variant="primary" type="submit">
                            Login
                        </Button>
                    </Form>
                </>
            ) : (
                <>
                    <h1 className="my-4">Order Management Dashboard</h1>
                    <Row>
                        <Col>
                            <Card className="order-count">
                                <Card.Body>
                                    <Card.Title>Open Orders</Card.Title>
                                    <Card.Text>{openOrdersCount}</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card className="order-count">
                                <Card.Body>
                                    <Card.Title>Printing Orders</Card.Title>
                                    <Card.Text>{printingOrdersCount}</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card className="order-count">
                                <Card.Body>
                                    <Card.Title>Complete Orders</Card.Title>
                                    <Card.Text>{completeOrdersCount}</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </>
            )}
        </Container>
    );
}

export default Home;
