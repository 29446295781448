import React, { useState } from 'react';
import axios from 'axios';
import { Form, Button, Card } from 'react-bootstrap';

function CreateColor({ onCreate }) {
    const [filamentTypesID, setFilamentTypesID] = useState('');
    const [color, setColor] = useState('');
    const [baseColorPriceModifier, setBaseColorPriceModifier] = useState('');
    const [brand, setBrand] = useState('');
    const [token, setToken] = useState(localStorage.getItem('token') || '');

    const handleCreate = async () => {
        try {
            const response = await axios.post('https://admin.printforgers.com/backend/createColor', {
                headers: { Authorization: token },
                filamentTypesID,
                color,
                base_color_price_modifier: baseColorPriceModifier,
                brand
            });
            alert(response.data.message);
            onCreate();
        } catch (error) {
            console.error('Failed to create color:', error);
        }
    };

    return (
        <Card className="CreateColor">
            <Card.Body>
                <Card.Title>Create New Color</Card.Title>
                <Form.Group>
                    <Form.Label>Filament Type ID</Form.Label>
                    <Form.Control
                        type="text"
                        value={filamentTypesID}
                        onChange={(e) => setFilamentTypesID(e.target.value)}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Color</Form.Label>
                    <Form.Control
                        type="text"
                        value={color}
                        onChange={(e) => setColor(e.target.value)}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Base Color Price Modifier</Form.Label>
                    <Form.Control
                        type="number"
                        value={baseColorPriceModifier}
                        onChange={(e) => setBaseColorPriceModifier(e.target.value)}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Brand</Form.Label>
                    <Form.Control
                        type="text"
                        value={brand}
                        onChange={(e) => setBrand(e.target.value)}
                    />
                </Form.Group>
                <Button onClick={handleCreate}>Create</Button>
            </Card.Body>
        </Card>
    );
}

export default CreateColor;
