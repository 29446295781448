import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import './OrderManager.css';
import OrderRow from './OrderRow';

const orderStatuses = ['printing', 'pendingPrint', 'shipped', 'delivered', 'canceled', 'refunded', 'pendingPayment'];

function OrderManager() {
  const [orders, setOrders] = useState({});
  const [visibleOrders, setVisibleOrders] = useState({});
  const [sortOrder, setSortOrder] = useState({});
  const [token, setToken] = useState(localStorage.getItem('token') || '');

  useEffect(() => {
    async function fetchOrders() {
      try {
        const allOrders = {};
        const initialVisibleOrders = {};
        for (const status of orderStatuses) {
          const response = await axios.get('https://admin.printforgers.com/backend/getOrdersByStatus', {
            headers: { Authorization: token },
            params: { status }
          });
          const fetchedOrders = Array.isArray(response.data.results) ? response.data.results : [];
          allOrders[status] = fetchedOrders;
          initialVisibleOrders[status] = fetchedOrders.slice(0, 5);
        }
        setOrders(allOrders);
        setVisibleOrders(initialVisibleOrders);
      } catch (error) {
        console.error(error);
      }
    }

    fetchOrders();
  }, []);

  const showMoreOrders = (status) => {
    setVisibleOrders(prevState => ({
      ...prevState,
      [status]: orders[status].slice(0, prevState[status].length + 5)
    }));
  };

  const toggleSortOrder = (status) => {
    setSortOrder(prevState => ({
      ...prevState,
      [status]: prevState[status] === 'asc' ? 'desc' : 'asc'
    }));

    const sortedOrders = [...orders[status]].reverse();
    setOrders(prevState => ({
      ...prevState,
      [status]: sortedOrders
    }));

    setVisibleOrders(prevState => ({
      ...prevState,
      [status]: sortedOrders.slice(0, prevState[status].length)
    }));
  };

  return (
    <Container className="OrderManager">
      <h1>Order Manager</h1>
      {orderStatuses.map(status => (
        <React.Fragment key={status}>
          <Row>
            <Col>
              <Card className="CardContainer">
                <Card.Body>
                  <Card.Title className="CardTitle">
                    {status.charAt(0).toUpperCase() + status.slice(1)} Orders
                    <Button variant="secondary" className="ml-3" onClick={() => toggleSortOrder(status)}>
                      {sortOrder[status] === 'asc' ? 'Newest First' : 'Oldest First'}
                    </Button>
                  </Card.Title>
                  {visibleOrders[status] && visibleOrders[status].length > 0 ? (
                    <>
                      {visibleOrders[status].map(order => (
                        <OrderRow key={order.orderID} order={order} className="OrderRow" />
                      ))}
                      {visibleOrders[status].length < orders[status].length && (
                        <Button variant="primary" onClick={() => showMoreOrders(status)}>
                          Show More
                        </Button>
                      )}
                    </>
                  ) : (
                    <p className="NoOrdersMessage">No {status} orders.</p>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <br />
          <br />
          <hr />
          <br />
          <br />
          <br />
        </React.Fragment>
      ))}
    </Container>
  );
}

export default OrderManager;
