import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';

function MaterialColorRow({ materialId, color, expanded, toggleExpand, handleInputChange, handleUpdate }) {
    const [localColor, setLocalColor] = useState(color.color);
    const [localBaseColorPriceModifier, setLocalBaseColorPriceModifier] = useState(color.base_color_price_modifier);
    const [localBrand, setLocalBrand] = useState(color.brand);

    useEffect(() => {
        setLocalColor(color.color);
        setLocalBaseColorPriceModifier(color.base_color_price_modifier);
        setLocalBrand(color.brand);
    }, [color]);

    const handleLocalInputChange = (setter) => (e) => {
        const { value } = e.target;
        setter(value);
    };

    const handleSave = () => {
        const updatedValues = {
            color: localColor,
            base_color_price_modifier: +localBaseColorPriceModifier,
            brand: localBrand
        };
        handleUpdate(+materialId, +color.colorsID, updatedValues);
    };

    return (
        <div className="ColorRow">
            <div onClick={() => toggleExpand(materialId, color.colorsID)}>
                <strong>{localColor}</strong> - {localBrand}
            </div>
            {expanded && (
                <div className="ColorDetails">
                    <Form.Group>
                        <Form.Label>Color Name</Form.Label>
                        <Form.Control
                            type="text"
                            value={localColor}
                            onChange={handleLocalInputChange(setLocalColor)}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Base Color Price Modifier</Form.Label>
                        <Form.Control
                            type="number"
                            value={localBaseColorPriceModifier}
                            onChange={handleLocalInputChange(setLocalBaseColorPriceModifier)}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Brand</Form.Label>
                        <Form.Control
                            type="text"
                            value={localBrand}
                            onChange={handleLocalInputChange(setLocalBrand)}
                        />
                    </Form.Group>
                    <Button onClick={handleSave}>Update</Button>
                </div>
            )}
            <div>
                <p>Filament Types ID: {materialId}</p>
                <p>Colors ID: {color.colorsID}</p>
            </div>
        </div>
    );
}

export default MaterialColorRow;
