import axios from 'axios';
import { useNavigate } from 'react-router-dom';

// Create an axios instance
const axiosInstance = axios.create({
  baseURL: 'https://admin.printforgers.com/backend',
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const navigate = useNavigate();
    if (error.response && error.response.status === 401) {
      // Token has expired or is invalid
      localStorage.removeItem('token');
      navigate('/'); // Redirect to login page
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
