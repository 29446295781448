import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { Navbar, Nav, Container } from 'react-bootstrap';

import Home from './components/Home';
import OrderManager from './components/OrderManager/OrderManager';
import MaterialManager from './components/MaterialManager/MaterialManager';
import PrinterManager from './components/PrinterManager';
import ProtectedRoute from './components/ProtectedRoute';

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar bg="dark" variant="dark" expand="lg">
          <Container>
            <Navbar.Brand href="/">Printforgers Admin Panel</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link as={Link} to="/">Home</Nav.Link>
                <Nav.Link as={Link} to="/OrderManager">Order Manager</Nav.Link>
                <Nav.Link as={Link} to="/MaterialManager">Material Manager</Nav.Link>
                <Nav.Link as={Link} to="/PrinterManager">Printer Manager</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>

        <Container className="mt-4">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/OrderManager" element={<ProtectedRoute element={OrderManager} />} />
            <Route path="/MaterialManager" element={<ProtectedRoute element={MaterialManager} />} />
            <Route path="/PrinterManager" element={<ProtectedRoute element={PrinterManager} />} />
          </Routes>
        </Container>
      </Router>
    </div>
  );
}

export default App;
