import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Modal, Button, Row, Col, Form, Container } from 'react-bootstrap';
import './OrderPopup.css';
import { StlViewer } from "react-stl-viewer";

function OrderPopup({ order, setClicked }) {
    const [materialName, setMaterialName] = useState('');
    const [colorName, setColorName] = useState('');
    const [infillPercentage, setInfillPercentage] = useState('');
    const [STLOrderArray, setSTLOrderArray] = useState([]);
    const [orderStatus, setOrderStatus] = useState(order.status);
    const [shippingProvider, setShippingProvider] = useState(order.shippingProvider || '');
    const [shippingName, setShippingName] = useState(order.shippingName || '');
    const [realShippingCost, setRealShippingCost] = useState(order.realShippingCost || '');
    const [trackingNumber, setTrackingNumber] = useState(order.tracking_number || '');
    const token = localStorage.getItem('token') || '';

    const style = {};

    useEffect(() => {
        async function fetchOrderAttributes() {
            try {
                const response = await axios.get('https://admin.printforgers.com/backend/getNamesFromID', {
                    headers: { Authorization: token },
                    params: {
                        materialId: order.filamentTypesID,
                        colorId: order.colorsID,
                        infillPercentageId: order.infillPercentageID
                    }
                });
                if (response.data) {
                    setMaterialName(response.data.material);
                    setColorName(response.data.color);
                    setInfillPercentage(response.data.infillPercentage);
                }
            } catch (error) {
                console.error('Failed to fetch order attributes:', error);
            }
        }

        if (order) {
            fetchOrderAttributes();
        }
    }, [order, token]);

    useEffect(() => {
        async function getSTLsInOrder() {
            try {
                const response = await axios.get('https://admin.printforgers.com/backend/getSTLsInOrder', {
                    headers: { Authorization: token },
                    params: {
                        orderID: order.orderID
                    }
                });
                if (response.data) {
                    console.log(response.data.results)
                    setSTLOrderArray(response.data.results);
                }
            } catch (error) {
                console.error('Failed to fetch STLs:', error);
            }
        }

        if (order) {
            getSTLsInOrder();
        }
    }, [order, token]);

    async function downloadSTL(location, fileName) {
        const url = `https://admin.printforgers.com/backend/fileSender?id=${encodeURIComponent(location)}&fileName=${encodeURIComponent(fileName)}`;
        try {
            const response = await fetch(url, {
                headers: {
                    'Authorization': token
                }
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const blob = await response.blob();
            const anchor = document.createElement('a');
            const urlBlob = window.URL.createObjectURL(blob);
            anchor.href = urlBlob;
            anchor.download = fileName;
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);
            window.URL.revokeObjectURL(urlBlob);
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }
    }

    function updateStatus() {
        async function updateStatusFunction() {
            try {
                const response = await axios.get('https://admin.printforgers.com/backend/updateOrderStatus', {
                    headers: { Authorization: token },
                    params: {
                        client_reference_id: order.client_reference_id,
                        status: orderStatus
                    }
                });
                if (response.data) {
                    window.location.reload();
                }
            } catch (error) {
                console.error('Failed to update status:', error);
            }
        }

        if (order) {
            updateStatusFunction();
        }
    }

    function updateShippingInfo() {
        async function updateShippingInfoFunction() {
            try {
                const response = await axios.get('https://admin.printforgers.com/backend/updateShippingInfo', {
                    headers: { Authorization: token },
                    params: {
                        client_reference_id: order.client_reference_id,
                        shippingProvider: shippingProvider,
                        shippingName: shippingName,
                        realShippingCost: realShippingCost,
                        tracking_number: trackingNumber
                    }
                });
                if (response.data) {
                    window.location.reload();
                }
            } catch (error) {
                console.error('Failed to update shipping information:', error);
            }
        }

        if (order) {
            updateShippingInfoFunction();
        }
    }

    return (
        <Modal show={true} onHide={() => setClicked(false)} size="lg" centered>
            <Modal.Header closeButton>
                <Modal.Title>Order Information</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col><strong>Client Reference ID:</strong> {order.client_reference_id || 'Loading...'}</Col>
                        <Col><strong>Total Cost:</strong> ${order.totalCost / 100 || 'Loading...'}</Col>
                        <Col><strong>Shipping Estimate:</strong> ${order.shippingCost / 100 || 'Loading...'}</Col>
                    </Row>
                    <Row>
                        <Col><strong>Material:</strong> {materialName || 'Loading...'}</Col>
                        <Col><strong>Color:</strong> {colorName || 'Loading...'}</Col>
                        <Col><strong>Infill Percentage:</strong> {infillPercentage + "%" || 'Loading...'}</Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col><strong>Name:</strong> {order.name || 'Loading...'}</Col>
                        <Col><strong>Email:</strong> {order.email || 'Loading...'}</Col>
                    </Row>
                    <Row>
                        <Col><strong>Street 1:</strong> {order.street1 || 'Loading...'}</Col>
                        <Col><strong>Street 2:</strong> {order.street2 || 'N/A'}</Col>
                    </Row>
                    <Row>
                        <Col><strong>City:</strong> {order.city || 'Loading...'}</Col>
                        <Col><strong>Country:</strong> {order.country || 'Loading...'}</Col>
                    </Row>
                    <Row>
                        <Col><strong>State:</strong> {order.state || 'Loading...'}</Col>
                        <Col><strong>Zip:</strong> {order.zip || 'Loading...'}</Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col xs={12} md={6} className='align-self-center'>
                            <Form.Group controlId="formOrderStatus">
                                <Form.Label><strong>Status:</strong></Form.Label>
                                <Form.Control as="select" value={orderStatus} onChange={(e) => setOrderStatus(e.target.value)}>
                                    <option value="pendingPrint">Pending Print</option>
                                    <option value="printing">Printing</option>
                                    <option value="shipped">Shipped</option>
                                    <option value="delivered">Delivered</option>
                                    <option value="refunded">Refunded</option>
                                    <option value="canceled">Canceled</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={6} className='align-self-center'>
                            <Button variant="primary" onClick={updateStatus}>Update Status</Button>
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col xs={12} md={6} className='align-self-center'>
                            <Form.Group controlId="formShippingProvider">
                                <Form.Label><strong>Shipping Provider:</strong></Form.Label>
                                <Form.Control type="text" value={shippingProvider} onChange={(e) => setShippingProvider(e.target.value)} />
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={6} className='align-self-center'>
                            <Form.Group controlId="formShippingName">
                                <Form.Label><strong>Shipping Name:</strong></Form.Label>
                                <Form.Control type="text" value={shippingName} onChange={(e) => setShippingName(e.target.value)} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6} className='align-self-center'>
                            <Form.Group controlId="formRealShippingCost">
                                <Form.Label><strong>Real Shipping Cost:</strong></Form.Label>
                                <Form.Control type="number" value={realShippingCost} onChange={(e) => setRealShippingCost(e.target.value)} />
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={6} className='align-self-center'>
                            <Form.Group controlId="formTrackingNumber">
                                <Form.Label><strong>Tracking Number:</strong></Form.Label>
                                <Form.Control type="text" value={trackingNumber} onChange={(e) => setTrackingNumber(e.target.value)} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} className='align-self-center'>
                            <Button variant="primary" onClick={updateShippingInfo}>Update Shipping Info</Button>
                        </Col>
                    </Row>
                    <hr />
                    <div className="STLOrderContainer">
                        {STLOrderArray.length > 0 ? STLOrderArray.map((object, index) => (
                            <>
                                <div key={index} className='STLRow'>
                                    <Row>
                                        <Col>
                                            <p><strong>File:</strong> {object.fileName}</p>
                                            <p><strong>Print Time:</strong> {((object.printTimeInSeconds / 60) / 60).toFixed(2)} hours</p>
                                            <p><strong>Quantity:</strong> {object.quantity}</p>
                                            <p><strong>Price: ${object.basePrice/100}</strong></p>
                                        </Col>
                                        <Col>
                                            <p><strong>X size:</strong> {object.xSize}</p>
                                            <p><strong>Y size:</strong> {object.ySize}</p>
                                            <p><strong>Z size:</strong> {object.zSize}</p>
                                            <p><strong>Weight:</strong> {object.weightInGrams}g</p>
                                        </Col>
                                        <Col>
                                            <p className='LocationPElement'><strong>Location:</strong> {object.location}/{object.fileName}</p>
                                            <Button variant="secondary" onClick={() => { downloadSTL(object.location, object.fileName) }}>Download STL</Button>
                                        </Col>
                                        <Col>
                                            <StlViewer
                                                modelProps={{ color: "rgb(199,255,255)" }}
                                                style={style}
                                                orbitControls
                                                url={`https://admin.printforgers.com/backend/fileSender?id=${encodeURIComponent(object.location)}&fileName=${encodeURIComponent(object.fileName)}`}
                                                key={object.id}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                                <hr />
                            </>
                        )) : (
                            <p>No STL files to display.</p>
                        )}
                    </div>
                </Container>
            </Modal.Body>
        </Modal>
    );
}

export default OrderPopup;
