import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Card, Button } from 'react-bootstrap';
import './MaterialManager.css';
import MaterialColorRow from './MaterialColorRow';
import CreateFilament from './CreateFilament';
import CreateColor from './CreateColor';

function MaterialManager() {
    const [materials, setMaterials] = useState([]);
    const [expanded, setExpanded] = useState({});
    const [showCreateFilament, setShowCreateFilament] = useState(false);
    const [showCreateColor, setShowCreateColor] = useState(false);
    const [token, setToken] = useState(localStorage.getItem('token') || '');

    const fetchMaterials = async () => {
        try {
            const response = await axios.get('https://admin.printforgers.com/backend/getAllMaterials',{
                headers: { Authorization: token },
            });
            setMaterials(response.data.results);
        } catch (error) {
            console.error('Failed to fetch materials:', error);
        }
    };

    useEffect(() => {
        fetchMaterials();
    }, []);

    const handleInputChange = (e, materialId, colorId, field) => {
        const { value } = e.target;
        setMaterials(prevMaterials => prevMaterials.map(material => {
            if (material.filamentTypesID === materialId && material.colorsID === colorId) {
                return { ...material, [field]: value };
            }
            return material;
        }));
    };

    const handleUpdate = async (materialId, colorId, updatedValues) => {
        const material = materials.find(material => material.filamentTypesID === materialId && material.colorsID === colorId);
        if (!material) {
            console.error('Material not found');
            return;
        }

        const completeUpdateValues = {
            materialName: material.materialName,
            base_material_price_modifier: material.base_material_price_modifier,
            description: material.description,
            ...updatedValues
        };

        try {
            const response = await axios.post('https://admin.printforgers.com/backend/updateMaterial', {
                filamentTypesID: materialId,
                colorsID: colorId,
                ...completeUpdateValues
            });
            alert(response.data.message);
        } catch (error) {
            console.error('Failed to update material:', error);
        }
    };

    const toggleExpand = (materialId, colorId) => {
        setExpanded(prevState => ({
            ...prevState,
            [`${materialId}-${colorId}`]: !prevState[`${materialId}-${colorId}`]
        }));
    };

    const groupedMaterials = materials.reduce((acc, material) => {
        if (!acc[material.filamentTypesID]) {
            acc[material.filamentTypesID] = {
                materialName: material.materialName,
                base_material_price_modifier: material.base_material_price_modifier,
                description: material.description,
                colors: []
            };
        }
        acc[material.filamentTypesID].colors.push(material);
        return acc;
    }, {});

    const handleFilamentCreated = () => {
        setShowCreateFilament(false);
        fetchMaterials(); // Refetch materials after creation
    };

    const handleColorCreated = () => {
        setShowCreateColor(false);
        fetchMaterials(); // Refetch materials after creation
    };

    return (
        <Container className="MaterialManager">
            <h1>Material Manager</h1>
            <Button onClick={() => setShowCreateFilament(true)}>Create New Filament</Button>
            <Button onClick={() => setShowCreateColor(true)}>Create New Color</Button>
            {showCreateFilament && <CreateFilament onCreate={handleFilamentCreated} />}
            {showCreateColor && <CreateColor onCreate={handleColorCreated} />}
            {Object.keys(groupedMaterials).map(materialId => (
                <Card key={materialId} className="MaterialCard">
                    <Card.Body>
                        <Card.Title>{groupedMaterials[materialId].materialName}</Card.Title>
                        <Card.Text>
                            Base Material Price Modifier: {groupedMaterials[materialId].base_material_price_modifier}
                        </Card.Text>
                        <Card.Text>
                            Description: {groupedMaterials[materialId].description}
                        </Card.Text>
                        {groupedMaterials[materialId].colors.map(color => (
                            <MaterialColorRow
                                key={`${materialId}-${color.colorsID}`}
                                materialId={materialId}
                                color={color}
                                expanded={expanded[`${materialId}-${color.colorsID}`]}
                                toggleExpand={toggleExpand}
                                handleInputChange={handleInputChange}
                                handleUpdate={handleUpdate}
                            />
                        ))}
                    </Card.Body>
                </Card>
            ))}
        </Container>
    );
}

export default MaterialManager;
