import React, { useEffect, useState } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import './OrderRow.css';  // Ensure you create corresponding CSS for styling
import OrderPopup from './OrderPopup';

function OrderRow({ order }) {
    const [clicked, setClicked] = useState(false);
    const [backgroundColor, setBackgroundColor] = useState('white');
    const [token, setToken] = useState(localStorage.getItem('token') || '');

    useEffect(() => {
        console.log(order);
    }, [clicked]);

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    return (
        <>
            <Card
                className="OrderRow"
                style={{ background: backgroundColor }}
                onClick={() => setClicked(!clicked)}
                onMouseOver={() => setBackgroundColor('#f8f9fa')}
                onMouseLeave={() => setBackgroundColor('white')}
            >
                <Card.Body>
                    <Row>
                        <Col><p>Total Cost: ${order.totalCost / 100}</p></Col>
                        <Col><p>Status: {order.status}</p></Col>
                        <Col><p>Order Date: {formatDate(order.createDate)}</p></Col>
                    </Row>
                    <Row>
                        <Col>Tracking Number: {order.tracking_number || "None"}</Col>
                    </Row>
                </Card.Body>
            </Card>
            {clicked && (
                <OrderPopup order={order} setClicked={setClicked} />
            )}
            <br/>
        </>
    );
}

export default OrderRow;
